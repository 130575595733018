/* eslint quote-props: 0 */  // --> OFF
/* eslint quotes: 0 */  // --> OFF
/* uv-prod */
export const AppConfigCustom = {
  "APPAK": "web-a7969879-36b1-4342-a4ef-8609934459b2",                             //web-key from incomingAPIs.json
  "CLOUD_REGION": "us-east-1",
  "COGNITO_USER_POOL": "us-east-1_7zaBzr4q3",
  "COGNITO_USER_POOL_CLIENT_ID": "k504c9pg88g6hqanao5h1pcef",
  "ENDPOINT": "https://api.dashboard.remotifai.com",     //Invoke URL of ApiGateway
  // "ES_ENDPOINT": "https://api.dashboard.remotifai.com",   //Invoke URL of ApiGateway
  "allowCamConfig": false,
  "allowLocal": false,
  "emailSuffix" : "@remotifai.com",
  "ENABLE_REFRESH_VIOLATION":true,
  "ENABLE_ADVANCE_SETTINGS":false,
  "ENABLE_ALARM_NOTIFICATION":true,
  "ENABLE_FR_SETTINGS":false,
  "SHOW_FOOTER_INFO":true,
  "advancedShieldSettingUrl":"https://settings.dashboard.remotifai.com/",
  "loginURL":"https://dashboard.remotifai.com/login/cm",
  // "logoName":"alibi",
  "logoName":"cameramanager",
  // "headerLogoName":"alibi-logo-white",
  "headerLogoName":"een-logo-white",
  "product":"alibi",
  "cmLoginURL":"https://rest.cameramanager.com/oauth/authorize?scope=write&client_id=devtest1&response_type=code&redirect_uri=",
  "frontendSecretKey":"eendashboard-dashboard",
  "allowAuditConfig":true,
  "allowEventReviews":true,
  "autoUpdateEvent": false,
  "eventQueryLimit": 1000,
  "eventDownloadLimit": 1000,
  "allowBoundingBox":false,
  "agentStatusTimer":5, // in mins
  "agentStatusEnabled":true,
  "dashboardEventQueryLimit": 100,
  "eventRefreshInterval": 10000,
  "imageFeedRefreshInterval": 5000,
  "parkingFeedRefreshInterval": 5000,
  "jpegPlayerRefreshInterval": 2000,
  "IDLE_TIMEOUT" : 1000*60*120,
  "SHOW_VIOLATION":false,
  "SHOW_VIDEO":true,
  "SHOW_DIRECT_LINK_EVENT":false,
  "ENABLE_REPORT_DOWNLOAD": true,
  "PDF_LIMIT":9000,
  "EXCEL_LIMIT":30000,
  "NAME": "Eagle Eye Networks",
  "mapK": "AhBVLeZEl9lnaie15GuW_pVIAUAMcmsWT-IGiDBmVLmBIVsaG0hMn9bm6ZN_yvDj",
  "viewMode": "List",
  "adminDashboard": "/orgs",
  "ES_EVENT_TYPES": {},
  "DEFAULT_ZONEINFO": 'Asia/Calcutta',
  "userDefaultSettings": {
    ALERT_EMAIL: "OFF",
    ALERT_TELEGRAM:"OFF",
    EVENT_VISIBILITY: "0",
    EVENT_EMAIL_ALERT: "3",
    CAMERAS: [],
    CAMERAS_TELEGRAM: [],
  },
  "userRoles": [
    {
      name: 'ADMIN',
      key: 'ADMIN',
    },
    {
      name: 'REGULAR',
      key: 'REGULAR',
    },
    {
      name: 'RESIDENT',
      key: 'RESIDENT',
    },
  ],
  placeTypes: [],
  camTypes: [],
  orgTypes: []
}